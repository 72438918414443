.wrapper {
  display: flex;
  justify-content: space-between;
  padding: var(--space-1) var(--space-2);
  border-width: 1px;
}

.poweredBy {
  color: var(--color-text-secondary);
  display: inline-flex;
  align-items: center;
  gap: var(--space-1);
}

.result {
  display: inline-flex;
  align-items: center;
}
