.popper :global .MuiAutocomplete-paper {
  margin-top: calc(var(--space-1) / 2);
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
  border: 1px solid var(--color-border-light);
}

.popper :global .MuiAutocomplete-listbox {
  max-height: unset;
}

.popper :global .MuiListSubheader-root {
  line-height: 22px;
  margin-bottom: var(--space-1);
}

.popper :global .MuiAutocomplete-option,
.popper :global .MuiListSubheader-root {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.input :global .MuiOutlinedInput-root {
  padding: 0;
}

.input input {
  font-weight: bold;
  min-width: 0px !important;
}

.input.withAdornment input {
  padding-right: 24px !important;
}

.adornment {
  margin-left: 0;
  margin-right: 4px;
  position: absolute;
  right: 0;
}
