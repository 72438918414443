.profileImg {
  border-radius: var(--space-2);
  display: block;
}

.bubble {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.bubble span {
  outline: 1px solid var(--color-background-paper);
}

.profileData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.text {
  font-size: 12px;
  line-height: 14px;
}

.actionButtons {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
}
