.profileImg {
  border-radius: var(--space-2);
  width: 32px;
  height: 32px;
}

.profileData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginProgress {
  margin-bottom: -16px;
  top: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.passwordWrapper {
  padding: var(--space-4) var(--space-4) var(--space-2) var(--space-4);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: var(--space-1);
}

.loginError {
  width: 100%;
  margin: 0;
}
