.container :global .MuiDialog-paper {
  width: 480px;
  margin: 0;
  position: relative;
  overflow-y: visible;
  margin-bottom: 60px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}
