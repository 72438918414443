.container {
  padding: var(--space-2) var(--space-1);
}

.info {
  padding: 0 var(--space-1);
}

.safe {
  display: flex;
}

.iconButtons {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconButton {
  color: var(--color-primary-main);
  background-color: var(--color-secondary-background);
  width: 32px;
  height: 32px;
}

.iconButton:hover {
  background-color: var(--color-secondary-background);
}

.address {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.nouns {
  width: 120px;
  position: relative;
  height: 120px;
  border: 2px solid var(--color-secondary-main);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 5;
  border-radius: 6px 0px 6px 6px;
  overflow: hidden;
}

.nouns_hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-secondary-main);
  border-radius: 12px;
  padding: 12px;
  justify-content: center;
  font-size: 20px;
  z-index: 10;
  align-items: center;
  display: flex;
  opacity: 0;
  /*transition: all 0.2s ease-in-out; */
}

.nouns:hover .nouns_hover {
  opacity: 1;
}

.nouns:hover > *:not(.nouns_hover) {
  filter: brightness(0.5);
}

.nounAvatar {
  border-radius: 0 !important;
}

.superchainInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.superchainLevel {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  height: 34px;
  background-color: var(--color-secondary-main);
  border-radius: 0px 100px 100px 0px;
  padding: 12px;
  width: 76px;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.superchainLevel span {
  font-weight: 600;
}

.superChainData {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
}

.superChainId {
  margin: 0;
  font-weight: 600;
  color: var(--color-text-primary);
  font-size: 16px;
  max-width: 160px;
  white-space: normal;
  display: flex;
  flex-wrap: wrap;
}
.superChainId span {
  color: var(--color-secondary-main);
}

.superChainData_points {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-secondary);
}

.superChainData_points span {
  color: var(--color-text-primary);
}
