.container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  line-height: 1.4;
  width: 100%;
}

.avatarContainer {
  flex-shrink: 0;
  position: relative;
}

.avatarContainer > * {
  width: 100% !important;
  height: 100% !important;
}

.addressContainer {
  display: flex;
  align-items: center;
  gap: 0.25em;
  white-space: nowrap;
}

.disabled {
  filter: grayscale(1);
}

.removeEoa {
  background-color: var(--color-primary-light);
  word-break: keep-all;
}

.removeInvite {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary-light);
  word-break: keep-all;
  padding: 0 9px 0 9px;
  gap: 6px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  border: 0;
  justify-self: flex-end;
}
