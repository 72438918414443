.row {
  width: 100%;
  padding: var(--space-4) var(--space-7);
}

@media (max-width: 599.95px) {
  .row {
    padding: var(--space-2);
  }
}
