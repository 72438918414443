.hint {
  padding: var(--space-2);
  border: unset;
}

.hint :global .MuiAlert-icon {
  padding: 0;
}

.hint :global .MuiAlert-message {
  padding: 0;
}

.wrapperBox :global .MuiAccordion-root.Mui-expanded {
  border-color: var(--color-border-light) !important;
}

.wrapperBox {
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
  padding: 0;
  background-color: var(--color-background-main);
  line-height: 1;
}

.loader {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  padding-right: 12px;
  justify-self: flex-end;
}

.balanceChanges {
  max-height: 300px;
  overflow-y: auto;
  align-items: center;
  gap: var(--space-1);
}

.balanceChange {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

.balanceChange:last-child {
  margin-bottom: 0;
}

.balanceChange svg {
  flex-shrink: 0;
}

.nftId {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.categoryChip {
  border-radius: 4px;
  height: auto;
}

.box {
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
  display: grid;
  grid-template-columns: 35% auto;
  padding: var(--space-2) 12px;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.checkboxError {
  color: var(--color-error-main);
  animation: popup 0.5s ease-in-out;
}

.checkboxError svg {
  color: var(--color-error-main) !important;
}

.redefine {
  height: 40px;
  width: 52px;
  position: absolute;
  right: -58px;
}
