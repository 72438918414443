.dropbox {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: var(--space-3) var(--space-5);
  margin: var(--space-3) 0;
  background: var(--color-secondary-background);
  color: var(--color-primary-light);
  transition: border 0.5s, background 0.5s;
}

.verticalLine {
  display: flex;
  height: 18px;
  border-right: 1px solid var(--color-primary-main);
  margin-left: 7px;
  margin-top: -8px;
}
