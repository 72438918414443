.submit:hover {
  background-color: var(--color-secondary-main);
}
.list {
  gap: var(--space-2);
  display: flex;
  flex-direction: column;
}
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-1) var(--space-2);
  border: 1px solid var(--color-text-primary);
  border-radius: 0.5rem;
}

.list-item span {
  font-weight: bold;
}
